import "./event.scss";
import * as React from "react";
import {
  TypeEventPageQueryResult,
  getPage,
  TypeEventPageData,
} from "../utils/queries/event";
import { SectionDivider } from "../components/section-divider/section-divider";
import Linked from "../utils/linkedItem";
import Helmet from "react-helmet";
import linkResolver from "../utils/linkResolver";
import AmazeePage from "../components/layout";
import AmazeeIoMetadata from "../components/metadata";
import HubspotForm from "../components/hubspot-form";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../components/page-header/page-header";
import { PageTitle } from "../components/title/title";
import RichTextRender from "../utils/rich-text";
import usePreviewData from "../utils/usePreviewData";
import { ResourceInteruption } from "../templates/resources/customers";
import { QuoteCarousel } from "../components/reusable/quote-carousel";
import { TypeHomepageV2Quote } from "../utils/queries/homepagev2";
import {
  CustomerStandardCard,
  StandardCard,
} from "../templates/resources/cards";
import { Resource } from "../utils/queries/resources";

const EventPage = ({ pageContext, location }: any) => {
  const result = usePreviewData(
    pageContext.data,
    "prismicEventPage",
    true
  ) as TypeEventPageData;
  const renderableResources = result.featured_content_group.map(
    (rawResource) => new Resource(rawResource.feature_link.document.data)
  );

  const showWhatIsHappeningSection = (result: TypeEventPageData): Boolean => {
    if (
      result.whats_happening.length ||
      result.whats_happening_schedule.length ||
      result.whats_happening_schedule_title ||
      result.whats_happening_subtitle
    ) {
      return true;
    }
    return false;
  };

  const title = result?.meta_title ? result?.meta_title : result?.title;

  return (
    <AmazeePage location={location}>
      <div id="event-page" className={result.event_identifier}>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css"
          />
          {result.canonical_url && (
            <link rel="canonical" href={`${result.canonical_url}`} />
          )}
        </Helmet>

        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          header={result.title}
          preHeader={result.event_title_intro}
          subheader={result.subtitle}
          withSiteName={true}
          image={result.hero_image}
        />
        {result.info_bar_date ||
        result.info_bar_entry ||
        result.info_bar_time ? (
          <section className="info_bar_section_container">
            <div className="flex justify-center px-4 md:px-5 lg:px-0">
              <div className="info_bar_section lg:flex py-6 md:grid md:grid-cols-2">
                <p className="">
                  <span className="font-bold pr-2">Date:</span>
                  {result.info_bar_date}
                </p>
                <span className="text-white px-2 hidden lg:inline-block">
                  |
                </span>
                <p className="">
                  <span className="font-bold pr-2">Time:</span>
                  {result.info_bar_time}
                </p>
                <span className="text-white px-2 hidden lg:inline-block">
                  |
                </span>
                <div className="inline-flex with-anchor">
                  <span className="font-bold pr-2">Venue:</span>
                  {RichTextRender(result.info_bar_venue)}
                </div>
                <span className="text-white px-2 hidden lg:inline-block">
                  |
                </span>
                <p>
                  <span className="font-bold pr-2">Entry:</span>
                  {result.info_bar_entry}
                </p>
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}

        <div className="page-content">
          {result?.form_lower_left_text?.text ||
          result.form_upper_left_text.text ? (
            <>
              <div className="container mt-8 px-20 top-upper-wrapper">
                <div className="row flex justify-center">
                  <div className="col-12 col-lg-10">
                    <div className="grid lg:grid-cols-2 gap-6">
                      <div className="top-upper-lower-text">
                        <div className="upper-only">
                          {RichTextRender(result.form_upper_left_text)}
                        </div>
                        <div className="lower-only">
                          {RichTextRender(result.form_lower_left_text)}
                        </div>
                      </div>
                      <div>
                        {result.hubspot_form_id && (
                          <section id="form_section">
                            <div className="container">
                              <div className="text-center">
                                {RichTextRender(result.hubspot_section_title)}
                              </div>

                              {result?.hubspot_section_subtitle && (
                                <div className="text-center">
                                  <h6>
                                    {RichTextRender(
                                      result.hubspot_section_subtitle
                                    )}
                                  </h6>
                                </div>
                              )}

                              <div className="text-center">
                                <HubspotForm
                                  formId={result.hubspot_form_id}
                                  portalId={result.hubspot_form_portal_id}
                                />
                              </div>
                            </div>
                          </section>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {result?.speakersGroup.length || result.section_title ? (
            <section id="speakers" className="main-speakers">
              <div className="container">
                <div className="row flex justify-center">
                  <div className="col-12 col-lg-10 text-center">
                    <h3>{result.section_title}</h3>
                  </div>
                </div>

                <div className="row">
                  {result?.speakersGroup?.map((speaker, index) => (
                    <div key={index} className="speaker grid grid-cols-2 gap-8">
                      <div className="image-talk-wrap">
                        <img
                          className="talk-image"
                          src={speaker.talk_image.url}
                          alt={speaker.talk_image.alt}
                        />
                      </div>
                      <div className="md:pr-12 details-talk-wrap">
                        <div className="talk-title">
                          <h3>{speaker.talk_title}</h3>
                        </div>
                        <div className="talk-date-time talk-info flex">
                          <img
                            className="svg-icons-speaker"
                            src="/images/events/Calendar-Icon.svg"
                          />
                          {speaker.talk_time_date}
                        </div>
                        <div className="talk-location talk-info flex">
                          <img
                            className="svg-icons-speaker"
                            src="/images/events/Location-Icon.svg"
                          />
                          {speaker.talk_location}
                        </div>
                        {speaker.talk_tags.text && (
                          <div className="talk-tags flex">
                            <img
                              className="svg-icons-speaker"
                              src="/images/events/Tag-Icon.svg"
                            />
                            {RichTextRender(speaker.talk_tags)}
                          </div>
                        )}
                        <div className="flex items-center speaker-profile">
                          <img
                            className="circle"
                            src={speaker.speaker_headshot.url}
                            alt={speaker.speaker_headshot.alt}
                          />
                          <div className="speaker-name-blurb">
                            {RichTextRender(speaker.speakers_name)}
                          </div>
                        </div>
                        {speaker.speaker_headshot_2.url && (
                          <div className="flex items-center speaker-profile second-speaker">
                            <img
                              className="circle"
                              src={speaker.speaker_headshot_2.url}
                              alt={speaker.speaker_headshot_2.alt}
                            />
                            <div className="speaker-name-blurb">
                              {RichTextRender(speaker.speakers_name_2)}
                            </div>
                          </div>
                        )}
                        {speaker.speaker_headshot_3.url && (
                          <div className="flex items-center speaker-profile third-speaker">
                            <img
                              className="circle"
                              src={speaker.speaker_headshot_3.url}
                              alt={speaker.speaker_headshot_3.alt}
                            />
                            <div className="speaker-name-blurb">
                              {RichTextRender(speaker.speakers_name_3)}
                            </div>
                          </div>
                        )}
                        <div className="description">
                          {RichTextRender(speaker.talk_description)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}

          {result.book_a_meeting_cta_image.url && (
            <section id="book_meeting_with_us">
              <div className="container">
                <div className="row flex justify-center">
                  <div className="col-12 col-md-7 flex justify-center flex-col items-center">
                    <img
                      src={result.book_a_meeting_cta_image.url}
                      alt={result.book_a_meeting_cta_image.alt}
                    />
                    <h3 className="mt-4 mb-0 text-center">
                      {result.book_a_meeting_text}
                    </h3>
                    <div className="flex buttons mt-3">
                      <a href={result.book_a_meeting_cta_link}>
                        <div className="btn-boldish amazeeio-button rounded-full px-4 py-2 inverted inline-block">
                          {result.book_a_meeting_cta_text}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {/* Update */}
          {result.upper_image.url ||
            (result.speakersGroupLower && (
              <section id="upper_text_wrap" className="text-upper-lower">
                <div className="container">
                  {result.upper_image.url && (
                    <div className="row flex justify-center">
                      <div className="text-image grid grid-cols-2 gap-8">
                        <div className="">
                          {RichTextRender(result.upper_copy)}
                        </div>
                        <div className="upper-inner-images">
                          <img
                            src={result.upper_image.url}
                            alt={result.upper_image.alt}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {/* {result.upper_copy &&
                  <div className="row flex justify-center">
                    <h3 className="title-wrap">
                      Don’t miss our Open Stage Exhibition Session
                    </h3>
                  </div>
              } */}

                  <div className="row">
                    {result.speakersGroupLower.map((speakerLower, index) => (
                      <div
                        key={index}
                        className="speaker grid grid-cols-2 gap-8"
                      >
                        <div className="image-talk-wrap">
                          <img
                            className="talk-image"
                            src={speakerLower.talk_image.url}
                            alt={speakerLower.talk_image.alt}
                          />
                        </div>
                        <div className="md:pr-12 details-talk-wrap">
                          <div className="talk-title">
                            <h3>{speakerLower.talk_title}</h3>
                          </div>
                          <div className="talk-date-time talk-info flex">
                            <img
                              className="svg-icons-speaker"
                              src="/images/events/Calendar-Icon.svg"
                            />
                            {speakerLower.talk_time_date}
                          </div>
                          <div className="talk-location talk-info flex">
                            <img
                              className="svg-icons-speaker"
                              src="/images/events/Location-Icon.svg"
                            />
                            {speakerLower.talk_location}
                          </div>
                          {speakerLower.talk_tags.text && (
                            <div className="talk-tags flex">
                              <img
                                className="svg-icons-speaker"
                                src="/images/events/Tag-Icon.svg"
                              />
                              {RichTextRender(speakerLower.talk_tags)}
                            </div>
                          )}
                          <div className="flex items-center speaker-profile">
                            <img
                              className="circle"
                              src={speakerLower.speaker_headshot.url}
                              alt={speakerLower.speaker_headshot.alt}
                            />
                            <div className="speaker-name-blurb">
                              {RichTextRender(speakerLower.speakers_name)}
                            </div>
                          </div>
                          <div className="description">
                            {RichTextRender(speakerLower.talk_description)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            ))}

          {result.middle_image.url && (
            <section
              id="middle_text_wrap_giveaway"
              className="text-upper-lower"
            >
              <div className="container">
                <div className="row flex justify-center">
                  <div className="text-image grid grid-cols-2 gap-8">
                    <div className="">
                      <img
                        src={result.middle_image.url}
                        alt={result.middle_image.alt}
                      />
                    </div>
                    <div className="text-copy">
                      {RichTextRender(result.middle_text)}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {showWhatIsHappeningSection(result) ? (
            <section id="whats_happening">
              <div className="container">
                <div className="row flex justify-center">
                  <div className="col-12 col-lg-10 text-center">
                    {RichTextRender(result.whats_happening_title)}
                  </div>
                  {result.whats_happening_subtitle && (
                    <div className="col-12 col-lg-6 text-center">
                      <h6>{RichTextRender(result.whats_happening_subtitle)}</h6>
                    </div>
                  )}
                </div>

                <div className="row box-parent flex justify-center">
                  {result.whats_happening.map((happening, index) => (
                    <div key={index} className="col-12 col-lg-4 box">
                      <img
                        src={happening.whats_happening_image.url}
                        alt={happening.whats_happening_image.alt}
                      />
                      <div className="box-inner">
                        <div className="box-uppertext">
                          {happening.whats_happening_introduction}
                        </div>
                        <div className="box-description">
                          {RichTextRender(
                            happening.whats_happening_description
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {result.whats_happening_schedule_title && (
                  <div className="row schedule-wrap">
                    <div className="col-12 text-center">
                      {result.show_cta_to_form &&
                      result.whats_happening_schedule.length == 0 ? (
                        <h4>
                          {RichTextRender(
                            result.whats_happening_schedule_title
                          )}
                        </h4>
                      ) : (
                        <h3>
                          {RichTextRender(
                            result.whats_happening_schedule_title
                          )}
                        </h3>
                      )}
                    </div>
                    <div className="col-12 col-lg-8 grid grid-cols-2 gap-4 schedule-inner">
                      {result.whats_happening_schedule.map(
                        (schedule, index) => (
                          <div key={index} className="schedule">
                            <div className="description">
                              {RichTextRender(
                                schedule.whats_happening_schedule_text
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
                {result.show_cta_to_form && (
                  <div className="flex justify-center w-full">
                    <a
                      href="#form_section"
                      className="btn-boldish amazeeio-button rounded-full px-4 py-2 inverted my-3 inline-block px-4 py-1 cursor-pointer"
                    >
                      Register today
                    </a>
                  </div>
                )}
              </div>
            </section>
          ) : (
            <></>
          )}

          {result.lower_image.url && (
            <section id="lower_text_wrap" className="text-upper-lower">
              <div className="container">
                <div className="row flex justify-center">
                  <div className="text-image grid grid-cols-2 gap-8">
                    <div className="text-copy">
                      {RichTextRender(result.lower_text)}
                    </div>
                    <div className="">
                      <img
                        src={result.lower_image.url}
                        alt={result.lower_text.alt}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {result.section_title2 && (
            <section id="speakers" className="live-in-booth">
              <div className="container">
                <div className="row flex justify-center">
                  <div className="col-12 col-lg-10 text-center">
                    <h3>{result.section_title2}</h3>
                  </div>
                </div>

                <div className="row">
                  {result.speakersGroup2.map((session, index) => (
                    <div key={index} className="speaker grid grid-cols-2 gap-8">
                      <div>
                        <img
                          src={session.talk_image2.url}
                          alt={session.talk_image2.alt}
                        />
                      </div>
                      <div className="md:pr-12">
                        <div className="talk-title">
                          <h3>{session.talk_title2}</h3>
                        </div>
                        <div className="talk-date-time talk-info flex">
                          <img
                            className="svg-icons-speaker"
                            src="/images/events/Calendar-Icon.svg"
                          />
                          {session.talk_time_date2}
                        </div>
                        <div className="talk-location talk-info flex">
                          <img
                            className="svg-icons-speaker"
                            src="/images/events/Location-Icon.svg"
                          />
                          {session.talk_location2}
                        </div>
                        {session.talk_tags_live_in_booth.text && (
                          <div className="talk-tags flex">
                            <img
                              className="svg-icons-speaker"
                              src="/images/events/Tag-Icon.svg"
                            />
                            {RichTextRender(session.talk_tags_live_in_booth)}
                          </div>
                        )}
                        <div className="flex items-center speaker-profile second-speaker">
                          <img
                            className="circle"
                            src={session.speaker_headshot2.url}
                            alt={session.speaker_headshot2.alt}
                          />
                          <div className="speaker-name-blurb">
                            {RichTextRender(session.speakers_name2)}
                          </div>
                        </div>
                        {session.speaker_headshot3.url && (
                          <div className="flex items-center speaker-profile second-speaker">
                            <img
                              className="circle"
                              src={session.speaker_headshot3.url}
                              alt={session.speaker_headshot3.alt}
                            />
                            <div className="speaker-name-blurb">
                              {RichTextRender(session.speakers_name3)}
                            </div>
                          </div>
                        )}
                        <div className="description">
                          {RichTextRender(session.talk_description2)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}
          {/* {result.hubspot_form_id && (
            <section id="form_section">
              <div className="container">
                <div className="row flex justify-center">
                  <div className="col-12 col-lg-10 text-center">
                    <h3>{RichTextRender(result.hubspot_section_title)}</h3>
                  </div>
                  <div className="col-12 col-lg-8 text-center">
                    <h6>{RichTextRender(result.hubspot_section_subtitle)}</h6>
                  </div>
                </div>

                <div className="row flex justify-center">
                  <div className="col-12 col-lg-8 text-center">
                    <HubspotForm
                      formId={result.hubspot_form_id}
                      portalId={result.hubspot_form_portal_id}
                    />
                  </div>
                </div>
              </div>
            </section>
          )} */}
        </div>

        {result.section_title2 && (
          <section id="quotes">
            <div className="container">
              <div className="row">
                <div className="col-12 quote-carousel-section">
                  <QuoteCarousel
                    withComma={true}
                    quotes={result.quotes.map(
                      (quote) =>
                        ({
                          image: quote.logo,
                          text: quote.quote,
                          author_name: quote.author_name,
                          author_title: quote.author_position,
                        } as TypeHomepageV2Quote)
                    )}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
        {result.featured_content_title && (
          <section id="customers">
            <div className="container">
              <div className="row flex justify-center">
                <div className="col-12 col-lg-8 text-center">
                  <h3>{RichTextRender(result.featured_content_title)}</h3>
                  <h6>{RichTextRender(result.featured_content_subtitle)}</h6>
                </div>
              </div>

              <div className="row">
                {renderableResources.map((resource, index) =>
                  resource.companyName ? (
                    <CustomerStandardCard resource={resource} key={index} />
                  ) : (
                    <StandardCard resource={resource} key={index} />
                  )
                )}
              </div>
            </div>
          </section>
        )}
        {/* <ResourceInteruption
          cta_text={result.cta_text}
          cta_button_text={result.cta_button_text}
          second_cta_button_text={result.second_cta_button_text}
          cta_link={result.cta_link}
          second_cta_button_link={result.second_cta_button_link}
          topMargin={false}
        /> */}
      </div>
    </AmazeePage>
  );
};

export default EventPage;
